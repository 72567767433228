import $ from "jquery";
import { getSize } from "../../common/layout/useWindowSize";

export function adsViews(url, params) {
	let adsViewsInterval = setInterval(function () {
		if ($(".native-ad-image").length > 0) {
			params.view = getView();
			params.ads = [];
			$(".ad").each(function() {
				let adId = $(this).attr('id');
				let locationId = adId.replace('ad_wrapper_', '');
				let adButlerContentContainer = $(this).find('div[eligible-callback]');
				if(adButlerContentContainer.length > 0){
					let adButlerUrl = adButlerContentContainer.attr('eligible-callback');
					const adButlerIds = adButlerUrl.substr(adButlerUrl.search(';'), adButlerUrl.length);
					let adButlerIdsValues = adButlerIds.split(';').filter(function(adButlerIdsValue) {
						return adButlerIdsValue.includes('BID') || adButlerIdsValue.includes('MID');
					});
					adButlerIdsValues.push('location_id=' + locationId);
					params.ads.push(adButlerIdsValues.join(';'))
				}

			})
			$.get(url, params);
			clearInterval(adsViewsInterval);
		}
	}, 1000);
}

function getView() {
	const { isMobile } = getSize();
	return isMobile ? "mobile" : "desktop";
}
