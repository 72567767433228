import InfiniteMarquee from "vanilla-infinite-marquee";

export function marquee() {
	const marquees = document.querySelectorAll(".marquee");

	marquees.forEach((marquee) => {
		const button = marquee.querySelector(".marquee__control");
		const marqueeList = marquee.querySelector(".marquee__list");

		button.addEventListener("click", (e) => {
			e.preventDefault();
			marquee.classList.toggle("marquee--paused");
			button.classList.toggle("marquee__control--enabled");
			marqueeList.classList.toggle("paused");
		});
	});

	new InfiniteMarquee({
		element: "#breakingNews .marquee__list",
		speed: 25000,
		duplicateCount: 7,
	});
}
